const baseUrl =
  process.env.NODE_ENV === "production"
    ? "/api/"
    : "https://res-uat.ascentis.com.sg/api/";
//: "http://localhost:5000/api/";
const languages = [
  { name: "English", code: "en" },
  { name: "Vietnamese", code: "vi" }
];
const currency = "$";
// need to update recaptcha domain once moved to production
// const recaptchaKey = "6LcOId0cAAAAAJPkHTXuiCuTeVZPD62FcNvnD482" - PROD
// const recaptchaKey = "6LfmId0cAAAAAJNkTVGuoGBqTCeBSgG0uLQAXoHh" - UAT
const recaptchaKey =
  process.env.NODE_ENV === "production"
    ? "6LfmId0cAAAAAJNkTVGuoGBqTCeBSgG0uLQAXoHh"
    : "6LfmId0cAAAAAJNkTVGuoGBqTCeBSgG0uLQAXoHh"; // UAT
const appStoreUrl = "#";
const ggStoreUrl = "#";
const maxTransferAmount = 5000000;
const timeoutConfigs = {
  warnTimeoutMins: 8,
  logoutTimeoutMins: 10,
  refreshIntervalMins: 8
};
const captchaThreshold = 0;
const noOfStarsForFreeDrink = 25;
const qrCodePrefix = "https://www.abbott.com.sg/gift-card/?cardno=";
const herokuPassword = "asc_abbott@2019";
const googleMapKey =
  process.env.NODE_ENV === "production"
    ? "AIzaSyB-RVnMNUi0LeyCQSDQ_zviK_dtvMcbAOw"
    : "AIzaSyB-RVnMNUi0LeyCQSDQ_zviK_dtvMcbAOw";
const linkHub =
  process.env.NODE_ENV === "production"
    ? ""
    : "https://res-uat.ascentis.com.sg";

const ampBaseUrl =
  process.env.NODE_ENV === "production"
    ? "https://prod2-api-mportal.ascentis.com.sg"
    : "https://uat2-api-mportal.ascentis.com.sg";

const ampSettings = {
  baseUrl: ampBaseUrl,
  getOtpEndpoint: "/api/Crm/GetOtp",
  validateOtpEndpoint: "/api/Crm/ValidateOtp",
  smsMaskName: "RES Rewards",
  smsMessage:
    "RE&S: Your One-Time-Password (OTP) is [OTP]. This password will expire in 3 minutes. ",
  options: "X",
  charLen: 6,
  appNameBase64: "UkVT",
  validateOtpIgnoreCase: false,
  validateOtpTimeIntervalType: "Minute",
  validateOtpTimeIntervalValue: 3
};

export {
  baseUrl,
  languages,
  currency,
  recaptchaKey,
  maxTransferAmount,
  appStoreUrl,
  ggStoreUrl,
  timeoutConfigs,
  captchaThreshold,
  noOfStarsForFreeDrink,
  herokuPassword,
  qrCodePrefix,
  googleMapKey,
  linkHub,
  ampSettings
};
